import { computed } from "vue";
import { axiosInstance, scbAxiosInstance } from "../../services";
import RegisterBanner from "../../components/RegisterBanner/index.vue";
import KeyPartners from "../../components/KeyPartners/index.vue";
import Skeleton from "../../components/Skeleton/index.vue";
import Featured from "../../components/Featured/index.vue";
import orgImage from "@/../assets/images/blubirch_logo.png";
import { useAuth } from "../../stores";
import { isMobile } from "../../misc/index";
import { testimonials } from "../../static/index";
import groupImg from "@/../assets/images/group.svg";
import homeimg3 from "@/../assets/images/homeimg3.png";
import nomiddleImg from "@/../assets/images/no_middleman.png";
import sizesImg from "@/../assets/images/all_lot_sizes.png";
import altImg from "@/../assets/images/attractive_prices.png";

export default {
  setup() {
    const authStore = useAuth();
    const user = computed({
      get: () => authStore.user,
    });

    return {
      user,
      groupImg,
      homeimg3,
      altImg,
      sizesImg,
      nomiddleImg
    };
  },
  data() {
    return {
      services: [],
      testimonials: testimonials,
      loading: false,
    };
  },
  components: {
    RegisterBanner,
    Featured,
    KeyPartners,
    Skeleton
  },
  mounted() {
    this.getMarketPlace();
  },

  methods: {
    personData(index, attribute) {
      const userIndex =
        parseInt(testimonials.length / index) + (testimonials.length % index);
      return testimonials[userIndex - 1][attribute];
    },
    handleRedirect(link, bidType) {
      this.$router.push(link);
    },

    async getMarketPlace() {
      this.loading = true;
      let url1 = `/api/organizations/fetch_marketplace`;
      let url2 = "/api/external_service/get_marketplaces";
      let request1 = axiosInstance.get(url1).then(res => res).catch(err => err?.response);
      let request2 = scbAxiosInstance.get(url2).then(res => res).catch(err => err?.response);
      let [marketplace, scbMarketplace] = await Promise.all([
        request1,
        request2,
      ]);

      this.loading = false;

      let scbOpenMarketPlace = scbMarketplace?.data?.external_service?.map(
        (item) => {
          return {
            ...item,
            card_url: `/${item.card_url.split("/")[3]}/products`,
            type: "Open",
          };
        }
      ) || [];
      const remarkingLots = marketplace?.data?.marketplaces || [];
      var combinedResult = [
        ...remarkingLots,
        ...scbOpenMarketPlace,
      ];
      combinedResult = combinedResult.filter(rec => rec?.id);
      const activeServices = combinedResult.reduce((acc, item) => {
        const existingItem = acc.find(
          (element) =>
            element.name === item.name ||
            element.name.split(" ")[0] === item.name.split(" ")[0] ||
            element.name.split(".Ltd.Pvd")[0] === item.name.split(" ")[0]
        );

        if (existingItem) {
          existingItem.image = item.image_url;
          existingItem.lot_count += item.active_lots;
        } else {
          acc.push({
            ...item,
            name: item.name,
            lot_count: item.active_lots,
            link: item.card_url,
            bidType: item?.type || "Hybrid",
            image: item.image_url,
          });
        }
        return acc.splice(0, 4);
      }, []);

      const blubirchLots = activeServices.filter(a => a.id === 'bb');
      let otherLots = activeServices.filter(a => a.id !== 'bb');
      otherLots = otherLots.sort((a,b) => b.active_lots - a.active_lots);
      this.services = [...blubirchLots, ...otherLots]
    },
  },
};
